import React from "react";
import * as styles from "../../styles/components/cta-full-width-card.module.scss"
import {CtaCard} from "../../store/models/cta";
import StyledText, {TextStyles} from "../typography";
import Button from "../common/button/Button";

type Props = {
  ctaCard: CtaCard;
}

const CtaFullWidthCard = (props: Props) => {
  const {ctaCard} = props
  return (
    <div className={styles.container}>
        <StyledText variant={TextStyles.h2} className={styles.description}>
          {ctaCard.description}
        </StyledText>
        <div>
          <Button variant='contained' color='secondary' href={ctaCard.url?.link} trackInitiateEvent>
            <StyledText variant={TextStyles.button} tag="span">
              {ctaCard.label}
            </StyledText>
          </Button>
        </div>
    </div>
  )
}

export default CtaFullWidthCard;
